<script>
export default {
  data() {
    return {
      baujahr: [
        {
          text: "1950 bis 1969 - Uw 5,8 W/m²K",
          value: 5.8,
        },
        {
          text: "1970 bis 1976 - Uw 3,5 W/m²K",
          value: 3.5,
        },
        {
          text: "1977 bis 1994 - Uw 3,1 W/m²K",
          value: 3.1,
        },
        {
          text: "1995 bis 2001 - Uw 1,8 W/m²K",
          value: 1.8,
        },
        {
          text: "2002 bis heute - Uw 1,4 W/m²K",
          value: 1.4,
        },
      ],
      baujahrSelect: "3.1",
      fensterNeuUw: "1.2",
      flaecheFenster: 25,
      heizart: [
        {
          typ: "Heizöl",
          id: 1,
          preis: "1.3",
        },
        {
          typ: "Erdgas",
          id: 2,
          preis: "19",
        },
      ],
      heizartSelect: 2,
      heizartBeschreibung: "AKTUELLER GASPREIS (Ct/kWh)",
      heizartErgebnisLabel: "kwh Gas",
      preis: "19",
      zeigeErgebnis: false,
      tooltip: false,
    };
  },
  methods: {
    test() {
      alert("!");
    },
    formatNumberCurrency(number) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 0,
      }).format(number);
    },
    formatNumber(number) {
      return new Intl.NumberFormat("de-DE", {
        maximumFractionDigits: 0,
      }).format(number);
    },
  },
  computed: {
    ersparnisFaktor() {
      let res = "";
      switch (this.heizartSelect) {
        case 1:
          res = 12;
          break;
        case 2:
          res = 100;
          break;
      }
      return res;
    },
    literProFensterAlt() {
      const ergebnis =
        Math.round(
          parseFloat(this.baujahrSelect) * 100 * this.ersparnisFaktor
        ) / 100;
      return isNaN(ergebnis) ? 0 : ergebnis;
    },
    literProFensterNeu() {
      const ergebnis =
        Math.round(parseFloat(this.fensterNeuUw) * 100 * this.ersparnisFaktor) /
        100;
      return isNaN(ergebnis) ? 0 : ergebnis;
    },
    literProFenster() {
      const ergebnis =
        Math.round((this.literProFensterAlt - this.literProFensterNeu) * 100) /
        100;
      return isNaN(ergebnis) ? 0 : ergebnis;
    },
    ersparnisProFenster() {
      let ersparnis = this.literProFenster * this.preis;
      ersparnis = this.heizartSelect == 2 ? ersparnis / 100 : ersparnis;
      return isNaN(ersparnis) ? 0 : Math.round(ersparnis * 100) / 100;
    },
    ersparnisProGesamt() {
      const ergebnis =
        Math.round(
          this.ersparnisProFenster * parseFloat(this.flaecheFenster) * 100
        ) / 100;
      return isNaN(ergebnis) ? 0 : ergebnis;
    },
    preisNumberStep() {
      return this.heizartSelect == 2 ? "1" : "0.1";
    },
  },
  watch: {
    heizartSelect(newValue) {
      switch (newValue) {
        case 1:
          this.preis = "1.30";
          this.heizartBeschreibung = "AKTUELLER HEIZÖLPREIS (€/Liter)";
          this.heizartErgebnisLabel = "Liter Heizöl";
          break;
        case 2:
          this.preis = "19";
          this.heizartBeschreibung = "AKTUELLER GASPREIS (Ct/kWh)";
          this.heizartErgebnisLabel = "kwh Gas";
          break;
      }
    },
  },
};
</script>

<template>
  <div id="energie-rechner" @click="tooltip = false">
    <div class="imagewrapper">
      <img src="./assets/logo.jpg" alt="Fenster und Türen Welt Logo" />
    </div>
    <h2>ENERGIESPAR-RECHNER</h2>
    <div class="row">
      <div>
        <label> BAUJAHR IHRES FENSTERS* </label>
        <select v-model="baujahrSelect">
          <option
            v-for="option in baujahr"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>
      <div>
        <label> HEIZART </label>
        <select v-model="heizartSelect">
          <option
            v-for="option in heizart"
            :value="option.id"
            :key="option.value"
          >
            {{ option.typ }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div>
        <label> FLÄCHE IHRER FENSTER (m²) </label>
        <input type="number" v-model="flaecheFenster" />
      </div>
      <div>
        <label>
          {{ heizartBeschreibung }}
        </label>
        <input type="number" :step="preisNumberStep" v-model="preis" />
      </div>
    </div>
    <div class="row">
      <div>
        <label>
          UW-WERT DER NEUEN FENSTER (W/m²K)
          <span @click.stop="tooltip = !tooltip" class="tooltip">
            <i class="fa-solid fa-circle-info"></i
            ><span :class="{ active: tooltip }" class="tooltiptext"
              >Die mögliche Energieeinsparung ist abhängig vom Grad der Dämmung,
              also vom möglichst niedrigen Wärmeverlust eines Fensters. Der
              Kennwert dafür ist der Wärmedurchgangskoeffizient, der sogenannte
              Uw-Wert.</span
            ></span
          >
        </label>

        <input type="number" step="0.1" v-model="fensterNeuUw" />
      </div>

      <div class="button-wrapper">
        <button @click="zeigeErgebnis = true" class="bg-futw-red ripple">
          JETZT BERECHNEN
        </button>
      </div>
    </div>

    <div class="ergebnis" v-if="zeigeErgebnis">
      <h2>ERGEBNIS</h2>
      <p>Einsparpotential pro Jahr:</p>

      <div class="row card">
        <div class="ergebnis">
          <div class="bg-futw-red">{{ formatNumber(literProFenster) }}</div>
        </div>
        <div class="border">{{ heizartErgebnisLabel }} pro m² Fenster</div>
      </div>

      <div class="row card">
        <div class="ergebnis">
          <div class="bg-futw-red">
            {{ formatNumberCurrency(ersparnisProFenster) }}
          </div>
        </div>
        <div class="border">Ersparnis pro m² Fenster</div>
      </div>

      <div class="row card">
        <div class="ergebnis">
          <div class="bg-futw-red">
            {{ formatNumberCurrency(ersparnisProGesamt) }}
          </div>
        </div>
        <div class="border">Ersparnis Gesamtfläche</div>
      </div>

      <p>
        <small
          >Die ausgewiesenen Werte verstehen sich als ca. Werte, bezogen auf die
          Pauschalrechnung, Irrtum vorbehalten.</small
        >
      </p>
      <p>
        <small
          >*Die angegebenen Uw-Werte sind angelehnt an die zu der Zeit gültigen
          Wärmeschutz- bzw. Energiesparverordnungen.</small
        >
      </p>
      <p>
        <small
          >Wir können keine Gewähr übernehmen, ob die Werte Ihrer Fenster diesen
          entsprechen und die angegebenen Einsparmöglichkeiten wirklich erreicht
          werden.</small
        >
      </p>
    </div>
  </div>
</template>

<style>
</style>
